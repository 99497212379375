import { MESSAGES } from '@fanatics/resn-api/white-elephant';
import { defineComponent } from 'vue';

import { useFanatics } from './FanaticsProvider';
import { useWindowMessage } from '~/composables/useWindowMessage';

const StateManager = defineComponent({
    setup() {
        const fanatics = useFanatics();

        useWindowMessage({
            onReceiveMessage: (e) => {
                if (!e.data.type) {
                    console.warn('StateManager: Window message object does not have a type:', e);
                    return;
                }

                console.log('StateManager: Message from parent:', e.data);

                const { data } = e.data;

                if (e.data.type == MESSAGES.Values.SETUP) {
                    /*                     var shouldReset = fanatics.step.value !== null;
                                        if (shouldReset) {
                                            resetAndSetup(data);
                                            return;
                                        } */
                    fanatics.setupGame(data);
                } else {
                    fanatics.updateGameData(data);
                }
            },
        });

        /*         const resetAndSetup = (data) => {
                    console.log('🚀 ~ resetAndSetup ~ data:', data);
                }; */
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});

export default StateManager;
