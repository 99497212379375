<template>
    <EnvProvider>
        <DeviceLayoutProvider :mobileThreshold="VIDEO_SMALL">
            <GsapProvider>
                <MediaMetaProvider :data="mediaMetaData">
                    <FanaticsProvider>
                        <ColorPaletteProvider type="white-elephant">
                            <AudioProvider>
                                <StateManager>
                                    <FontLoaderProvider>
                                        <slot />
                                    </FontLoaderProvider>
                                </StateManager>
                            </AudioProvider>
                        </ColorPaletteProvider>
                    </FanaticsProvider>
                </MediaMetaProvider>
            </GsapProvider>
        </DeviceLayoutProvider>
    </EnvProvider>
</template>

<script setup>
    import { DeviceLayoutProvider, MediaMetaProvider } from '@resn/gozer-vue';

    import AudioProvider from '../AudioProvider';
    import ColorPaletteProvider from '../ColorPaletteProvider';
    import EnvProvider from '../EnvProvider';
    import FontLoaderProvider from '../FontLoaderProvider';
    import GsapProvider from '../gsap/GsapProvider.vue';
    import FanaticsProvider from './FanaticsProvider';
    import StateManager from './StateManager';
    import { VIDEO_SMALL } from '~/core/constants';
    import mediaMetaData from '~/media-metadata.json';
    import { useAppStore } from '~/stores/app';

    const store = useAppStore();
</script>

<style lang="scss">
    @import '@/styles/critical.scss';
    @import '@/styles/main.scss';
</style>
